/* input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
} */


.job-details-table tr td{white-space: break-spaces}
.addnotesbtn{
    padding: 6px;
    
    background: rgba(0, 0, 0, 0.80);
    align-items: center;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}
.addnotesbtn:hover{color: #fff;}
.chatboxmsg {
    position: fixed;
    right: 243px;
    z-index: 999;
    bottom: 20%;
    
    
} 
.chatboxmsg .react-grid-item{width: 220px;}
.chatboxmsg .notranslate.public-DraftEditor-content{height: 100px;}

 .chatboxfooter {
    position: fixed;
    right: 10px;
    bottom: 8%;
    z-index: 9;
    width: 247px;
}
.addnotesbtn .modal-backdrop{z-index: 1;}
.Addnotes-model-tittle{width: 40%;}
.icolors {
    padding: 0;
    margin: 0;
    list-style: none;
}
.icolors>li.red {
    background: red;
}
.icolors>li:first-child {
    margin-left: 0;
}
.icolors>li {
    padding: 0;
    margin: 2px;
    float: left;
    display: inline-block;
    height: 30px;
    width: 30px;
    background: #000000;
    text-align: center;
}
.icolors>li.green {
    background: green;
}
.icolors>li.blue {
    background: blue;
}
.icolors>li.yellow {
    background: #efef0ced;
}
.icolors>li.purple {
    background: purple;
}

.icolors .active{position: relative;}
.icolors .active:after {
    content: "\e9df" !important;
    font-family: 'boxicons';
    font-size: 20px;
    height: 30px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 30px;
}

.skills-data-table{width: 100%;display: flow-root;}
.skills-data-table tbody{width: 100%;display: flow-root;}
.skills-data-table tbody tr{width: 100%;display: flex;}
.skills-data-table tbody tr td:first-child {width: 23% !important;}
.skills-data-table tbody tr td:last-child {width: 77% !important;}
.skills-data-table tbody tr td:last-child p{display: inline;margin-bottom: 0;}
.btn.addnotesbtn .count-bx{width: 24px;height: 24px;margin-left: -7px;}
.add-note-bx {
    color: #34c38f;
    border-color: #34c38f;
    border: 1px solid;
    border-radius: 5px;
    padding: 1px 6px;
}
.btn.addnotesbtn .sticky-count-bx{background-color: white;
    width: 28px;
    height: 28px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;}

    .well.bg-red {
        background-color: red;
        color: #FFFFFF;
    }
    .well {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: rgba(0, 0, 0, 0.80);
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
        font-size: 12px;
        margin-bottom: 5px;
    }
.sticky-list-box{padding: 10px;background: #000000bf;    height: 400px;
    overflow-y: scroll;}
.edit-sik{margin-right: 15px;}
.sticky-list-box .well p{margin-bottom: 0px;}
.well.bg-green {background-color: green;
    color: #FFFFFF;
}
.well.bg-blue {
    background-color: blue;
    color: #FFFFFF;
}
.well.bg-yellow {
    background-color: #efef0ced;
    color: black;
}
.well.bg-purple {
    background-color: purple;
    color: #FFFFFF;
}
.icolors>li.active.yellow:after {
    color: black;
}
.icolors>li.active:after {
    content: "\2713 ";
    color: #ffffff;
    line-height: 30px;
}

.apexcharts-toolbar {
    position: absolute;
    z-index: 0 !important;
    max-width: 176px;
    text-align: right;
    border-radius: 3px;
    padding: 0px 6px 2px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resumepasing{background: #f8f8f8; padding: 1px 10px; border-radius: 31px;}
.tresumepasing{background: #f1f3ff; padding: 1px 10px; border-radius: 31px; margin-bottom: 1px;}

.tabledataflow{width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
    display: flex;
    flex-wrap: wrap;}

.em-note-box{margin:0px; padding:0px;}
.employe-note-chat{width:100%; list-style: none;}

.em-note-box{background:#fff;padding: 15px 10px; border-radius: 2px; border-bottom:1px solid #eee;}
/* .em-note-box:nth-child(even){background:#fff; padding: 6px 10px; border-radius: 2px; border-bottom:1px solid #eee;} */

.statisics-box{margin-left: 10px;}
.statisics-box a{color: #ffffff;background-color: transparent;border-color: #556ee6;padding: 6px 20px;font-size: 18px;
    border-radius: 0.3rem;border: 2px solid #ffffff;}
.statisics-table tbody tr td{padding: 10px 10px;}
.statisics-info-item p{font-size: 13px;font-weight: 500;color: #74788d;}
.statisics-info-item h6 span{font-size: 14px;font-weight: 500;margin-bottom: 8px;}
.statisics-table .table-light th{font-size: 14px;}
.main-content-statics .card-title{font-size: 15px;margin: 0 0 7px 0;font-weight: 600;color: #e81c28;}    
.breck{color:#e81c28}