.ui_courseDropdown{
    padding: 0.5em 1em;

    font-size: 1em;
}
.pikachu:hover{
color:#FF8000 !important
}
.doremon{
    width: 18rem;
    height: 8rem;
    /* margin: 4em; */
    cursor: pointer;
    box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.1);
    -webkit-box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.1);
    -moz-box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.1);
}
.doremon{
    color: red;
    width: 18rem;
    height: 8rem;
    /* margin: 4em; */
    /* cursor: pointer;
    box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.2);
    -webkit-box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.2);
    -moz-box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.2); */
}

.doremon:hover{
    box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.2);
}
.nobita{
    width: auto;
    height: auto;
    /* margin: 4em; */
    box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.1);
    -webkit-box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.1);
    -moz-box-shadow: 0px 0px 19px 9px rgba(122,120,120,0.1);
    position: relative;
}
.doremon-helicopter{
    position: absolute;
    right: -4px;
    top: -8px;
}
.pichu{
    border-radius: 5px !important;
    border: 1px solid grey !important;
}
.pichu:hover{
color:black !important;
background-color:lightgray !important;
border: 1px solid black;
}
.pikachu{
    border-radius: 5px !important;
    border: 1px solid red! important;
}
.pikachu:hover{
background-color: white !important;

color: red !important;

}


